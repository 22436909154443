<!-- browse database table 
<p-dialog [(visible)]="browse" [modal]="true" [style]="{width:'100vw',height:'100vh','margin':'60px'}" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <dialog-header icon="building" [title]="'Browse'" (onClose)="browse=false"></dialog-header>
  </ng-template>

  <ng-template pTemplate="content">
    <p-table [columns]="cols" [value]="value"
      selectionMode="single" [(selection)]="row"
      (onRowSelect)="selectFk(row)">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">{{ col.header }}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
            {{ rowData[col.field] }}
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div *ngIf="value?.length===0" class="empty">
      NO DATA
    </div>

  </ng-template>
</p-dialog> -->

<div [formGroup]="dynForm" class="p-4 formgrid grid">

  <div class="grid formgrid px-3 m-0">
    <ng-container *ngFor="let control of controls">

      <!-- input: text |  email | search | url -->
      <div *ngIf="isInput(control.type)" class="field col-12">
        <label [for]="control.key">{{ control.name }}</label>
        <span [class.p-input-icon-left]="control.icon" class="w-full">
          <i *ngIf="control.icon" class="pi pi-{{control.icon}}"></i>
          <input [id]="control.key"
            pInputText
            [formControlName]="control.key"
            [placeholder]="control.placeholder || ''"
            [type]="control.type"
            class="w-full">
        </span>
        <val-errors [controlName]="control.key" [label]="makeLabel(control.key)"></val-errors>
      </div>

      <!-- input: password -->
      <div *ngIf="control.type === 'password'" class="field col-12">
        <label [for]="control.key">{{ control.name }}</label>
        <span [class.p-input-icon-left]="control.icon" class="w-full">
          <i *ngIf="control.icon" class="pi pi-{{control.icon}}"></i>

          <p-password [id]="control.key"
            formControlName="control.key"
            [feedback]="false"
            [toggleMask]="true"
            styleClass="w-full"
            inputStyleClass="w-full"></p-password>
        </span>
        <val-errors [controlName]="control.key" label="makeLabel(control.key)"></val-errors>
      </div>

      <!-- textarea -->
      <div *ngIf="control.type === 'textarea'" class="field col-12">
        <label [for]="control.key">{{ control.name }}</label>
        <textarea [id]="control.key"
          pInputTextarea
          [rows]="control.rows || 3"
          [placeholder]="control.placeholder || ''"
          style="min-width:100%;max-width:100%"
          [formControlName]="control.key"
          [value]="control.value"></textarea>
        <val-errors [controlName]="control.key" [label]="makeLabel(control.key)"></val-errors>
      </div>

      <!-- checkbox -->
      <div *ngIf="control.type==='checkbox'" class="field col-12">
        <p-checkbox [id]="control.key"
          [binary]="false"
          [falseValue]=0
          [trueValue]=1
          [label]="control.name"
          [formControlName]="control.key"
          [value]="control.value">
        </p-checkbox>
        <val-errors [controlName]="control.key" [label]="makeLabel(control.key)"></val-errors>
      </div>

      <!-- select -->
      <div *ngIf="control.type==='select'" class="field col-12">
        <label [for]="control.key">{{ control.name }}{{ control.cascade ? '(v)' : '' }}</label>
        <p-dropdown
          [inputId]="control.key"
          [formControlName]="control.key"
          [options]="control.options"
          [autoDisplayFirst]="false"
          appendTo="body"
          styleClass="w-full">
        </p-dropdown>
      </div>

      <!-- fk browse -->
      <!-- <div *ngIf="control.type==='fk'" class="field col-12">
      <label [for]="control.key">{{ control.name }}</label>
      <span class="p-input-icon-right w-full">
        <i class="pi pi-chevron-circle-right cursor-pointer" (click)="selectRow(control)"></i>
        <input [id]="control.key" pInputText type="text"
          [formControlName]="control.key"
          class="w-full">
      </span>
    </div> -->
      <!-- number -->
      <div *ngIf="control.type==='number'" class="field col-12 w-full">
        <label [for]="control.key">{{ control.name }}</label>
        <p-inputNumber [inputId]="control.key"
          [formControlName]="control.key"
          [showButtons]="control.decimals===0"
          [useGrouping]="false"
          [placeholder]="control.placeholder"
          mode="decimal"
          [maxFractionDigits]="control.decimals || 0"
          class="barf w-full"
          styleClass="w-full">
        </p-inputNumber>
        <val-errors [controlName]="control.key" [label]="makeLabel(control.key)"></val-errors>
      </div>

      <!-- date -->
      <div *ngIf="control.type==='date'" class="field col-12">
        <label [for]="control.key">{{ control.name }}</label>
        <p-calendar [inputId]="control.key"
          [formControlName]="control.key"
          appendTo="body"
          [placeholder]="control.placeholder"
          [selectionMode]="control.dateMode"
          [showTime]="control.showTime"
          [showSeconds]="control.showTime && control.showSeconds"
          styleClass="w-full">
        </p-calendar>
        <val-errors [controlName]="control.key" [label]="makeLabel(control.key)"></val-errors>
      </div>

    </ng-container>
  </div>
</div>

<div class="card m-0 mt-2 p-1" [ngClass]="dynForm.valid?'bg-primary-50':'bg-red-50'">
  <pre [innerHTML]="sanitizer.bypassSecurityTrustHtml(paint(this.model))" class="m-0"></pre>
</div>
