import { AfterViewInit, Component, HostBinding } from "@angular/core"
import { DomSanitizer } from "@angular/platform-browser"
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"
import _ from "lodash"

import { PrimeModule } from "app/prime.module"
import { PopupFooterComponent } from "../popup-footer.component"
import { PopupHeaderComponent } from "../popup-header.component"
import { FooterComponent } from "../footer.component"
import { DynamicFormComponent } from "../../ojala/dynamic-form/dynamic-form.component"
import { SharedModule, paint } from "app/shared"

export enum PopupType {
  Tweet,
  Info,
  Alert,
  Confirm,
  Prompt,
  Json,
  Form,
  Component
}

export class PopupConfig {
  type: PopupType = PopupType.Tweet
  icon?: string
  title?: string
  text?: string
  timeout?: number = 0
  prompt?: string
  placeholder?: string
  value?: string
  json?: any
  model?: any = {}
  formConfig?: any = {}
  formValid?: (valid: boolean) => void
  spinner?: boolean = false
  acceptText?: string
  rejectText?: string
}

@Component({
  selector: "app-popup",
  standalone: true,
  imports: [ PrimeModule, SharedModule, PopupHeaderComponent, PopupFooterComponent, FooterComponent, DynamicFormComponent ],
  templateUrl: "./popup.component.html"
})
export class PopupComponent implements AfterViewInit {
  @HostBinding("class") hostClass = "flex flex-column max-h-90"
  config: PopupConfig = new PopupConfig()
  model: any = null
  valid = true
  PopupType = PopupType
  showHeader: boolean

  constructor(
    public ref: DynamicDialogRef,
    private dlg: DynamicDialogConfig,
    private sanitizer: DomSanitizer
  ) {
    this.config = this.dlg.data
    this.showHeader = (this.config.type != PopupType.Tweet)
    if (this.config.model) {
      this.model = _.cloneDeep(this.config.model)
    }
    if (this.config.type == PopupType.Json && this.config.json) {
      this.config.json = this.sanitizer.bypassSecurityTrustHtml(paint(this.config.json))
    }
    // this.convertDates()
  }

  ngAfterViewInit(): void {
    console.log("After Init")
    // const elm = document.getElementById("popupClose")
    // elm?.blur()
  }

  reject = () => this.ref?.destroy()

  accept = () => {
    if (this.valid) {
      console.log("ACCEPT", this.model)
      this.convertDates()
      this.config.model = this.model
      this.ref?.close(this.model || this.config.value || true)
    }
  }

  convertDates() {
    // const ctls = this.options.formConfig?.controls.filter(ctl => ctl.type == "date" && this.model[ctl.key])
    // ctls?.forEach(ctl => {
    //   if (Array.isArray(this.model[ctl.key]))
    //     this.model[ctl.key] = this.model[ctl.key].map(dt => this.convertDate(dt))
    //   else
    //     this.model[ctl.key] = this.convertDate(this.model[ctl.key])
    // })
  }

  convertDate(date: any) {
    // if (!date) return date
    // return (date instanceof Date) ? date.toISOString() : new Date(date)
  }
}
