/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, Output } from "@angular/core"
import { PrimeModule } from "app/prime.module"
import { PopupService } from "app/services"

@Component({
  selector: 'app-popup-header',
  standalone: true,
  imports: [ PrimeModule ],
  template: `
    <div class="dialog-header">
      <i *ngIf="icon" class="text-primary-600 text-2xl pi pi-{{icon}} mr-3"></i>
      <div class="text-xl flex-auto">{{ title }}</div>
      <button id="popupClose" pButton icon="pi pi-times" class="p-button-rounded p-button-text" (click)="onClose()"></button>
    </div>`
})
export class PopupHeaderComponent {
  @Input() title
  @Input() icon
  @Output() close = new EventEmitter()

  constructor(public ns: PopupService) {
    // ctor
  }

  onClose() {
    this.ns?.close()
    this.close.emit()
  }
}
