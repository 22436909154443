import { Injectable } from "@angular/core"
import { BehaviorSubject, Subject } from "rxjs"
import { SafeResourceUrl } from "@angular/platform-browser"
import { Profile, CompanyDto } from "app/shared"
import { environment } from "environments/environment"

export class IconEmit {
  icon: SafeResourceUrl
  name: string
}

@Injectable({ providedIn: "root" })
export class StoreService {
  // Profile of authenticated user
  public profile$ = new BehaviorSubject<Profile>(null)
  get profile(): Profile { return this.profile$.value }
  set profile(profile: Profile) { this.profile$.next(profile) }

  // Company information associated with authenticated user
  public company$ = new BehaviorSubject<CompanyDto>(null)
  get company(): CompanyDto { return this.company$.value }
  set company(company: CompanyDto) { this.company$.next(company) }

  // Company icon - may change based on role
  public icon$ = new BehaviorSubject<IconEmit>(null)
  get icon() { return this.icon$.value }

  // Selected tags in the tag panel
  public tags$ = new BehaviorSubject<string[]>([])
  get tags() { return this.tags$.value }

  public searchFor$: Subject<string> = new Subject<string>()

  isProd = () => environment.production // && environment.api.startsWith("https://dev")
  public prod$ = new BehaviorSubject<boolean>(this.isProd())
  get prod() { return this.prod$.value }
  set prod(value: boolean) { this.prod$.next(value) }
  
  public keys$ = new BehaviorSubject<string>("")
}
