import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  themes = ["mdc-dark-indigo", "mdc-light-indigo"]
  
  constructor(@Inject(DOCUMENT) private document: Document) { }
  
  _style: string = "light"
  toggleTheme = () => {
    this.style = (this._style == "dark" ? "light" : "dark")
  }
  
  _searchActive = false
  get searchActive() { return this._searchActive }
  set searchActive(active: boolean) { this._searchActive = active }
  
  _settingsActive = false
  get settingsActive() { return this._settingsActive }
  set settingsActive(active: boolean) {
    this._settingsActive = active
  }

  get style() { return this._style }

  set style(style: string) {
    if (style == "dark" || style == "light") {
      this._style = style
      const name: string = this.themes.find(t => t.includes(style))
      console.log("New theme:", name)
      // change the theme stylesheet
      let themeLink = this.document.getElementById('theme-css') as HTMLLinkElement
      themeLink.href = `assets/layout/styles/theme/${name}/theme.css`
      // override theme styles
      let styleLink = this.document.getElementById('style-css') as HTMLLinkElement
      styleLink.href = `assets/iproof/style-${style}.css`
    }
  }
}
