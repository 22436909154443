<app-popup-header *ngIf="showHeader"
  [icon]="config.icon"
  [title]="config.title"
  (onClose)="ref.close(null)">
</app-popup-header>

<ng-container [ngSwitch]="config.type">

  <!-- TWEET -->
  <div *ngSwitchCase="PopupType.Tweet" class="p-4 flex justify-content-center align-items-center" (click)="reject()">
    <i *ngIf="config.spinner" class="pi pi-spin {{config.icon}} mr-3 text-indigo-500 text-2xl"></i>
    <div [innerHTML]="config.text"></div>
  </div>

  <!-- INFO -->
  <div *ngSwitchCase="PopupType.Info">
    <div class="overflow-y-auto" class="p-4 border-noround">
      <div class="mb-4 flex align-items-center border-noround">
        <div [innerHTML]="config.text"></div>
      </div>
    </div>
  </div>

  <!-- CONFIRM -->
  <div *ngSwitchCase="PopupType.Confirm">
    <div class="overflow-y-auto" class="p-4 border-noround">
      <div class="mb-4 flex align-items-center border-noround">
        <div [innerHTML]="config.text"></div>
      </div>
    </div>
  </div>

  <!-- PROMPT -->
  <div *ngSwitchCase="PopupType.Prompt">
    <div class="overflow-y-auto" class="p-4">
      <div class="mb-4 flex align-items-center border-noround">
        <div [innerHTML]="config.text"></div>
      </div>
      <div class="grid field">
        <label *ngIf="config.prompt" for="prompt" class="my-0 mx-3 p-1">{{ config.prompt }}</label>
        <input id="prompt" type="text" pInputText placeholder="config.placeholder" [(ngModel)]="config.value">
      </div>
    </div>
  </div>

  <!-- JSON -->
  <div *ngSwitchCase="PopupType.Json">
    <div class="overflow-y-auto" class="p-4">
      <pre class="m-0" [innerHTML]="config.json"></pre>
    </div>
  </div>

  <!-- FORM -->
  <div *ngSwitchCase="PopupType.Form" class="my-1 overflow-y-auto" >
    <app-dynamic-form
      [controls]="config.formConfig"
      [model]="model"
      (formValid)="valid=$event;config?.formValid($event)">
    </app-dynamic-form>
  </div>

</ng-container>

<!-- when named app-popup-footer it doesn't work 
     even though in other dialogs the footer does work -->
<app-footer *ngIf="showHeader"
  [acceptText]="config.acceptText"
  (accept)="accept()"
  [rejectText]="config.rejectText"
  (reject)="ref.close(null)">
</app-footer>
