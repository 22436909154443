import { Injectable, inject } from "@angular/core"
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http"
import { DomSanitizer } from "@angular/platform-browser"
import { Router } from "@angular/router"

import { lastValueFrom, Observable, of, tap, map, timeout } from "rxjs"
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy"
import { jwtDecode }  from "jwt-decode"
import { NGXLogger } from "ngx-logger"
import _ from "lodash"

import { StoreService, IconEmit } from "./store.service"
import { PopupService } from "app/services"

import { environment } from "environments/environment"
import { Profile, Badge, ApiResponse, FilterModel, PagingDataSource, KeyMap, KeyVal } from "app/shared/models"
import { SkuDto, LotDto, CompanyDto, LocationItemDto, ItemSummaryDto, ItemDto, LwinDto } from "app/shared/dto"
import { CaseDto, CaseFilterDto, CustomerDto, ItemHistoryDto, LocationDto, LocationHistoryDto, LocationMapDto, LocationModelDto } from "app/shared/storage.dto"
import { FilterRanges, flip, pastels } from "app/shared"
import {
  AddCodesResp, ChangePasswordBody, CheckInBody, ForgotPasswordBody, ItemsCsvResp,
  ItemKeysBody, ItemPinsResp, LocationBody, LoginBody, LoginResp, LotBody,
  RegisterItemBody, SkuBody, SkusResp, MoveLocationBody, RegisterCaseBody,
  RegisterBottleBody,
  AssignItemBody
} from "app/shared/api.dto"

import allCountries from "../../assets/data/countries.json"
import { SelectItemGroup, TreeNode } from "primeng/api"
import { toKeyVal } from "app/shared"

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class CmsService {

  public _company: CompanyDto = null

  api = environment.api
  allCountries: { name: string, code: string }[] = []

  httpHeader = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "*/*"
    })
  }

  constructor(
    private http: HttpClient,
    public router: Router,
    public store: StoreService,
    private sanitizer: DomSanitizer,
    private log: NGXLogger
  ) {
    this.log.trace("*cmsService")

    this.allCountries.push(...allCountries as { name: string, code: string }[])

    store.company$.pipe(untilDestroyed(this)).subscribe(company => this._company = company)

    // store.profile$.pipe(untilDestroyed(this)).subscribe(profile => this._profile = profile)
    const profile = new Profile(JSON.parse(localStorage.getItem("profile")))
    this.checkVersion(profile)
    if (profile?.token) this.setProfile(profile)
  }

  checkVersion = async (profile) => {
    return
    if (profile?.version != environment.version) {
      profile.token = null
      this.logout()
    }
  }

  // Load data when booting the app using APP_INITIALIZER
  // App will wait until the promise returned is resolved
  bootLoad = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      resolve(true)
    })
  }

  // #region helper methods ---------------------------------------------------

  /**
   * Issue a GET (read) to the api endpoint
   * @returns the data portion of the response
   */
  async get<T>(method: string): Promise<T> {
    const req = `${this.api}/${method}`
    return lastValueFrom(
      this.http.get(req, this.httpHeader)
        .pipe(map((resp: ApiResponse) => resp.data as T))
    ).catch(e => { throw (e) })
  }

  /**
   * Issue a POST (create) to the api endpoint, passing in body data
   * @returns the data portion of the response
   */
  post = async <T>(method: string, data: any): Promise<T> => {
    const req = `${this.api}/${method}`
    return lastValueFrom(
      this.http.post(req, data, this.httpHeader)
        .pipe(map((resp: ApiResponse) => resp.data as T))
    ).catch(e => { throw (e) })
  }

  /**
   * Issue a PUT (replace) to the api endpoint, passing in body data
   * @returns the data portion of the response
   */
  put<T>(method: string, data: any): Promise<T> {
    const req = `${this.api}/${method}`
    return lastValueFrom(
      this.http.put<any>(req, data, this.httpHeader)
        .pipe(map((resp: ApiResponse) => resp.data as T))
    ).catch(e => { throw (e) })
  }

  /**
   * Issue a PATCH (update) to the api endpoint, passing in the
   * object id and body data
   * @returns the data portion of the response
   */
  patch = async <T>(method: string, id: number, data: any): Promise<T> => {
    const req = `${this.api}/${method}/${id}`
    return lastValueFrom(
      this.http.patch(req, data, this.httpHeader)
        .pipe(map((resp: ApiResponse) => resp.data as T))
    ).catch(error => { throw (error) })
  }

  /**
   * Issue a DELETE (delete) to the api endpoint
   // todo add an identifier for what to delete
   * @returns the data portion of the response
   */
  delete = async <T>(method: string): Promise<T> => {
    const req = `${this.api}/${method}`
    return lastValueFrom(
      this.http.delete(req, this.httpHeader)
        .pipe(map((resp: ApiResponse) => resp.data as T))
    ).catch(e => { throw (e) })
  }

  // #endregion

  // #region identification ---------------------------------------------------
  //
  // ping
  // pingpong
  // login            [POST] user/authenticate
  // logout
  // refreshProfile
  // forgotPassword   [POST] user/passwordForgot
  // changePassword   [POST] user/passwordChange
  // changeCompany    [POST] user/changeCompany/{companyId}
  // setProfile
  // listRoles        [GET]  accout/roles
  // listUsers        [GET]  accout/users

  /**
   * Return information about the app. Add host info if logged in.
   * @returns object with key-value pairs of info
   */
  ping = (): Promise<KeyMap<string>> => this.get(`ping`)

  /**
   * Call ping but with a specific timeout to test the api endpoints.
   * @param url api endpoint url
   * @param to timeout in ms
   * @returns result of the ping call
   */
  pingpong = (url: string, to: number = 2000): Promise<any> => {
    return lastValueFrom(
      this.http.get(`${url}/ping`, this.httpHeader)
        .pipe(timeout(to))
    ).catch(e => {
      console.log("PINGPONG error")
      throw (e)
    })
  }

  /**
   * Login to a host account using username and password.
   * If login is successful, the profile is is created and passed to the store.
   * @param username username of the AD account
   * @param password password of the AD account
   * @returns the newly created profile
   */
  login = (email, password): Promise<Promise<void>> => {
    const call = `${this.api}/user/authenticate`
    return lastValueFrom(
      this.http.post(call, { email, password } as LoginBody)
        .pipe(
          map((resp: ApiResponse) => new Profile(resp.data as LoginResp)),
          map(async profile => await this.setProfile(profile))))
  }

  /**
   * Log out of the host account. Create a new profile preserving only
   * the essential information.
   * @returns the new profile
   */
  logout = (): void => {
    localStorage.removeItem("profile")
    this.store.profile = null
    this.router.navigate(["auth/login"])
  }

  refreshProfile = (isProd: boolean): void => {
    const profile = new Profile(JSON.parse(localStorage.getItem("profile")))
    this.checkVersion(profile)
    if (profile.token) {
      this.setProfile(profile)
      this.store.prod$.next(isProd)
    }
  }

  forgotPassword = (email: string, returnUrl: string): Promise<void> => {
    return this.post(`user/passwordforgot`, { email, returnUrl } as ForgotPasswordBody)
  }

  changePassword = (model: ChangePasswordBody): Promise<void> => {
    return this.post<void>(`user/passwordchange`, model)
  }

  changeCompany = async (id): Promise<void> => {
    this.log.trace("*changeCompany")
    const repl = await this.post<any>(`user/changecompany/${id}`, {})
    const profile = new Profile(JSON.parse(localStorage.getItem("profile")))
    profile.companyId = id
    profile.token = repl.token
    this.setProfile(profile)
  }

  // role
  // email
  // companyId
  // firstName
  // lastName
  // emailConfirmed
  // companyAccess
  // exp
  // ....
  // unique_name
  // nbf
  // iat

  // Use the jwt in the profile to fill in the profile properties
  setProfile = async (profile: Profile): Promise<void> => {
    try {
      Object.assign(profile, jwtDecode(profile.token))
      profile.companyId = +profile.companyId
      profile.version = environment.version
      localStorage.setItem("profile", JSON.stringify(profile))
      this.store.profile = profile
      this.store.prod = environment.production
      await this.loadCompany(profile.companyId)
      this.router.navigate([environment.defaultRoute]) // , { queryParams: { q: environment.query }})
    } catch {
      this.log.warn("Error initializing account")
    }
  }

  listRoles = () => this.get<string[]>("account/roles")

  listUsers = () => this.get<Profile[]>("account/users")

  // #endregion


  // #region company ----------------------------------------------------------
  // company          [GET]  company/{companyId}
  // loadCompany
  // companies        [GET]  companies

  // [GET] company
  company = (id: number = null) => this.get<CompanyDto>(id ? `company/${id}` : "company")

  loadCompany = (id: number = null): Promise<CompanyDto> => {
    return new Promise(async (resolve, reject) => {
      try {
        const [company, customers, skus, filterRanges] = await Promise.all([
          this.company(id),
          this.customers(id),
          this.skus(id, false),
          this.filterRanges()
        ])
        company.customers = customers
        // company.logo = @inject(DomSanitizer).bypassSecurityTrustResourceUrl(company.logo64)
        company.logo = this.sanitizer.bypassSecurityTrustResourceUrl(company.logo64)
        company.logo64 = null
        company.skus = skus
        this.prepareCompanyData(company, filterRanges)
        // emit the data
        this.store.icon$.next({ icon: company.logo, name: company.companyName } as IconEmit)
        this.store.company = company
        this.store.tags$.next([])
        resolve(company)
      } catch {
        reject()
      }
    })
  }

  protected prepareCompanyData = (company: CompanyDto, filter: FilterRanges): void => {
    // create company badges from the tags
    const colors = pastels(15)
    const sets = []
    company.tags = Object.keys(company.allTagsAndOrder)
    company.badges = []

    for (let kv of company.groupedTags) {
      let group = Math.floor(kv.value / 100)
      const index = sets.indexOf(group)
      group = index >= 0 ? index : sets.push(group) - 1
      company.badges.push(new Badge({ tag: kv.key, group, bg: colors[group] }))
    }

    // set lot parent, initial match; create flattened list 'docs'
    company.docs = []
    company.skus.forEach(sku => {
      sku.match = true
      sku.lots.forEach(lot => {
        lot.parent = sku
        lot.match = true
      })
      company.docs.push(sku, ...sku.lots)
    })

    // pick company badges for skus and lots
    company.docs.forEach(doc => {
      doc.badges = doc.tags.map(tag => company.badges.find(t => t.tag === tag))
    })

    // create the tag tree
    let tagTree: TreeNode[] = []
    for (let kv of company.groupedTags) {
      let index = 100 * Math.floor(kv.value / 100)
      let label = company.tagCategory[index] || "Other"
      let node: TreeNode = tagTree.find(node => node.label == label)
      if (!node) {
        node = { label, children: [], selectable: false }
        tagTree.push(node)
      }
      node.children.push({ label: kv.key })
    }
    company.tagTree = tagTree

    let icons = {
      "Year": "clock",
      "Size": "",
      "Quantity": ""
    }
    let groupedTags: SelectItemGroup[] = []
    for (let kv of company.groupedTags) {
      let index = 100 * Math.floor(kv.value / 100)
      let label = company.tagCategory[index] || "Other"
      let group = groupedTags.find(node => node.label == label)
      if (!group) {
        group = { label, items: [] }
        groupedTags.push(group)
      }
      group.items.push({ label: kv.key, value: kv.value })
    }
    company.tagGroups = groupedTags

    company.hierarchies = toKeyVal<number>(company.hierarchyNames)

    // create the tree for the tags
    // let tagTree: TreeNode[] = []
    // _.forOwn(company.allTagsAndOrder, (order, tag) => {
    //   let index = 100 * Math.floor(+tag / 100)
    //   let label = company.tagCategory[index] || "Other"
    //   let node: TreeNode = tagTree.find(node => node.label == label)
    //   if (!node) {
    //     node = { label, children: [], selectable: false }
    //     tagTree.push(node)
    //   }
    //   node.children.push({ label })
    // })
    // company.tagTree = tagTree

    // set the countries for the item filter
    company.countries = this.allCountries.filter(c => filter?.countryCodes.includes(c.code))
  }

  companies = () => this.get<CompanyDto[]>("companies")

  // #endregion


  // #region sku - lot --------------------------------------------------------
  // skus             [GET]  skus/companyId?keys=false
  // createSku        [POST] sku
  // updateSku        [PUT]  sku
  // createLot        [POST] lot
  // updateLot        [PUT]  lot
  // addCodes         [POST] lot/addCodes

  // [GET] /skus/companyId?keys=false
  skus = (companyId: number = null, keys: boolean = false): Promise<SkusResp[]> => {
    return this.get<SkusResp[]>(companyId ? `skus/${companyId}?keys=${keys}` : `skus`)
  }

  // [POST] /sku {sku}
  createSku = (sku: SkuBody, companyId: number = 0): Promise<SkuDto> => {
    const api = companyId ? `sku/${companyId}` : `sku`
    return this.post<SkuDto>(api, sku)
  }

  // [PUT] /sku {sku}
  updateSku = (sku: SkuBody, companyId: number = 0): Promise<SkuDto> => {
    const api = companyId ? `sku/${companyId}` : `sku`
    return this.put<SkuDto>(api, sku)
  }

  // [POST] /lot {lot}
  createLot = (lot: LotBody, companyId: number = 0): Promise<LotDto> => {
    const api = companyId ? `lot/${companyId}` : `lot`
    return this.post<LotDto>(api, lot)
  }

  // [PUT] /lot {lot}
  updateLot = (lot: LotBody, companyId: number = 0): Promise<LotDto> => {
    const api = companyId ? `lot/${companyId}` : `lot`
    return this.put<LotDto>(api, lot)
  }

  // [POST] lot/addCodes
  addCodes = (lotId: string, num: number): Promise<AddCodesResp> => {
    if (num > 0) {
      return this.post(`lot/addCodes/${lotId}?numberNewCodes=${num}`, {})
    } else {
      return null
    }
  }

  // #endregion

  // #region item - -----------------------------------------------------------
  // item             [GET]  item/{itemId}
  // itemSummary      [GET]  item/summary/{itemId}
  // itemPins         [POST] item/pins
  // getQr            [GET]  /scan/qr/{itemId}
  // getNfc           [GET]  /scan/nfc/{itemId}
  // setCoordinates   [POST] /scan/coordinates/{itemId}
  // getItemsCsv      [GET]  lot/csv
  // filterRanges     [GET]  scan/filterRanges/{iProofId}
  // find
  // setKeyValues     [POST] item/keys/{iProofId}

  // [GET] item/{itemId}
  item = (itemId: string) => this.get<ItemDto>(`item/${itemId}`)

  // [GET] item/summary/{itemId}
  itemSummary = (itemId: string) => this.get<ItemSummaryDto>(`item/summary/${itemId}`)

  // [POST] item/pins
  itemPins = (filters: FilterModel) => this.post<ItemPinsResp>(`item/pins`, filters)

  // [GET] /scan/qr/<id>
  getQR = (id: string) => this.get<ItemSummaryDto>(`scan/qr/${id}`)

  // [GET] /scan/nfc/<id>
  getNfc = (id: string) => this.get<ItemSummaryDto>(`scan/nfc/${id}`)

  // POST /scan/coordinates/{id}
  setCoordinates = (id: string, lat: number, lng: number) =>
    this.post<void>(`scan/coordinates/${id}`, { latitude: lat, longitude: lng })

  // [GET] lot/csv/{lotId}
  getItemsCsv = (lotId: string): Promise<ItemsCsvResp> => {
    this.log.trace(`getItemsCsv ${lotId}`)

    const url = `${this.api}/lot/csv/${lotId}`
    const options = {
      responseType: "blob" as const,
      observe: "response" as const,
      headers: { "Content-Type": "application/json" }
    }

    // return { data, filename } as promise
    return lastValueFrom(
      this.http.get(url, options).pipe(
        map((resp: HttpResponse<Blob>) => {
          const cd = resp.headers?.get("content-disposition")
          const filename = cd.split('filename=')[1]?.split(';')[0]
          return { data: resp.body, filename }
        }))
    )

    // lastValueFrom(this.http.get(url, options))
    //   .then((result: HttpResponse<Blob>) => {
    //     const hdr = result.headers?.get("content-disposition")
    //     const filename = hdr.split('filename=')[1]?.split(';')[0]
    //     if (filename) saveAs(result.body, filename)
    //     return filename
    //   })
  }

  // [GET] lot/csvrfid/{lotId}
  getItemsCsvRfid = (lotId: string): Promise<ItemsCsvResp> => {
    this.log.trace(`getItemsCsvRfid ${lotId}`)

    const url = `${this.api}/lot/csvrfid/${lotId}`
    const options = {
      responseType: "blob" as const,
      observe: "response" as const,
      headers: { "Content-Type": "application/json" }
    }

    // return { data, filename } as promise
    return lastValueFrom(
      this.http.get(url, options).pipe(
        map((resp: HttpResponse<Blob>) => {
          const cd = resp.headers?.get("content-disposition")
          const filename = cd.split('filename=')[1]?.split(';')[0]
          return { data: resp.body, filename }
        }))
    )
  }

  // [GET] scan/filterRanges/{iProofId}
  filterRanges = (iProofId: string = null): Promise<FilterRanges> => {
    return this.get(`scan/filterranges${iProofId ? `/${iProofId}` : ""}`)
  }

  find = async (pds: PagingDataSource): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        this.log.debug(`Items paging ${pds.offset}`)
        // this.log.debug(JSON.stringify(pds, null, 2))
        var body = {
          searchTerms: pds?.terms,
          tags: pds?.tags,
          first: pds?.offset,
          count: pds?.limit,
          isiProofId: true
        }
        const reply = await this.post<any>(`item/find`, body)
        resolve(new PagingDataSource({
          terms: pds.terms,
          tags: pds.tags,
          offset: reply.first,
          limit: reply.count,
          total: reply.total,
          data: reply.results,
          refresh: pds.refresh
        }))
      } catch {
        reject()
      }
    })

    // const data = []
    // pds.data = []
    // pds.total = 8000
    // const name = pds?.tags.join(".")
    // for (let i = pds.offset i < pds.offset + pds.limit i++) {
    //   pds.data.push({
    //     iProofId: i,
    //     hierarchyName: name,
    //     description: `Item ${i}`
    //   })
    // }
    // return lastValueFrom(of(pds))
  }

  // [POST] item/keys/{iProofId}
  itemKeyValues = (iProofId: string, itemKeys: ItemKeysBody): Promise<string[]> => {
    return this.post<string[]>(`item/keys/${iProofId}`, itemKeys)
  }

  // #endregion

  // #region other ------------------------------------------------------------
  // asset            [GET]  asset/{guid}
  // postFile

  // [GET] asset/{guid}
  asset = (guid): Observable<Blob> => this.http.get(`${this.api}/asset/${guid}`, { responseType: "blob" })

  // postFile(fileToUpload: File): Observable<boolean> {
  //   const endpoint = "your-destination-url"
  //   const formData: FormData = new FormData()
  //   formData.append("fileKey", fileToUpload, fileToUpload.name)
  //   return this.http
  //     .post(endpoint, formData, { headers: null })
  //     .pipe(map(() => { return true }))
  //     .catch((e) => this.handleError(e))
  // }

  // #endregion

  // #region storage ----------------------------------------------------------
  // customers        [GET]  storage/customers
  // locations        [GET]  storage/locations
  // location         [GET]  storage/location/{locationId}
  // locationModels   [GET]  storage/location/models
  // checkin          [POST] storage/checkin
  // checkout         [POST] storage/checkout
  // locationItem     [GET]  storage/item/{seqId}
  // createLocation   [POST] storage/location
  // updateLocation   [POST] storage/location
  // moveLocation     [POST] storage/location/relocate
  // deleteLocation   [DELETE] location/{locationId}?mustBeEmpty={false|true}
  // registerItem     [POST] storgae/location/register
  // assignItem       [POST] storage/item/assign
  // locationHistory  [GET]  storage/location/history/{locationId}
  // itemHistory      [GET]  storage/location/history/{seqId}
  // locationMap      [GET]  storage/map/{index}
  // lwin             [POST]

  // [GET] storage/customers
  customers = (companyId: number = 0) => this.get<CustomerDto[]>(`storage/customers/${companyId || ""}`)

  // [GET] storage/locations
  locations = (companyId: number = 0) => this.get<LocationDto[]>(`storage/locations/${companyId || ""}`)

  // [GET] storage/location/{locationId}?recursive={true|false}
  location = (code: string, recursive = false) => this.get<LocationDto[]>(`storage/location/${code}?recursive=${recursive}`)

  // [GET] storage/location/models/{companyId}
  locationModels = (companyId: number = 0) => this.get<LocationModelDto[]>(`storage/locations/models/${companyId}`)

  // [POST] storage/checkin
  checkin = (body: CheckInBody) => this.post<void>("storage/checkin", body)

  // [POST] storage/checkout/{code}
  checkout = (code: string, isIproofId: boolean = true) => this.post<ItemHistoryDto>("storage/checkout", { code, isIproofId })

  // [GET] storage/item/{code}
  locationItem = (code: string, isIproofId: boolean = true) => this.get<CaseDto>(`storage/item/${code}?isIproofId=${isIproofId}`)

  // [POST] storage/location
  createLocation = (body: LocationDto) => this.post<void>(`storage/location`, body)

  // [PUT] storage/location
  updateLocation = (body: LocationDto) => this.put<void>(`storage/location`, body)

  // [POST] storage/location/move
  moveLocation = (body: MoveLocationBody) => this.put<void>(`storage/location/move`, body)

  // [DELETE] storage/location/{locationId}?mustBeEmpty={false|true}
  deleteLocation = (locationId: string) => Promise.reject("Not yet implemented")

  // [POST] storage/item/register
  registerItem = (body: RegisterItemBody | RegisterCaseBody | RegisterBottleBody) => this.post<void>("storage/item/register", body)

  // [POST] storage/item/assign
  assignItem = (body: AssignItemBody) => this.post<void>("storage/item/assign", body)

  // [GET] storage/location/history/{locationId}
  locationHistory = (code: string) => this.get<LocationHistoryDto[]>(`storage/location/history/${code}`)

  // [GET] storage/item/history/{code}?recursive=false&isIproofId=true
  itemHistory = (code: string, recursive: boolean = false, isIproofId: boolean = true) =>
    this.get<ItemHistoryDto[]>(`storage/item/history/${code}?isIproofId=${isIproofId}&recursive=${recursive}`)

  // [GET] storage/item/find
  findItems = (criteria: CaseFilterDto) => this.post<CaseDto[]>("storage/item/find", criteria)

  // [GET] storage/map/{index}
  locationMap = (index: number) => this.get<LocationMapDto>(`storage/map/${index}`)

  // [POST] lwin
  searchLwin = (snippet: string, limit: number) => {
    console.log("lwin", snippet)
    return this.post<LwinDto[]>(`lwin/find`, { snippet, limit })
  }

  // #endregion


  // #region non-api methods --------------------------------------------------

  /**
   * Return the tags of a given category a list of KeyVal<number>
   * @param category - category name
   * @returns - list of KeyVal<number>
   */
  getTagOptions(category: string): KeyVal<number>[] {
    const cats = flip(this._company.tagCategory)
    const base = cats[category]
    if (!base) return null
    const options = this._company.groupedTags.filter(tg => tg.value >= +base && tg.value < +base + 100)
    return options
  }

  // #endregion
}
