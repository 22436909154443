import { Injectable } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog"

import { MessageService } from "primeng/api"
import { PopupComponent, PopupConfig, PopupType } from "../components/popup/popup.component"


@Injectable({ providedIn: "root" })
export class PopupService {
  constructor(
    private dlg: DialogService,
    private ms: MessageService
  ) {
  }

  handle: any

  ref: DynamicDialogRef

  public close() {
    if (this.ref) {
      this.ref.destroy()
      this.ref = null
      if (this.handle >= 0) {
        clearTimeout(this.handle)
        this.handle = -1
      }
    }
  }

  // toast

  toast(summary: string, severity = "info", detail = null) {
    this.ms.add({ severity, summary, detail })
  }

  // convenience methods

  run(ctype: ComponentType<any>, options: any = {}) {
    this.close()
    return new Promise(resolve => {
      this.ref = this.dlg.open(ctype, {
        data: options,
        showHeader: false,
        closeOnEscape: true,
        dismissableMask: false,
        draggable: false,
        style: {
          "width": "50%",
          "max-width": "800px",
          "min-width": "350px"
        },
        baseZIndex: 10
      })
      if (options?.timeout > 0) {
        this.handle = setTimeout(() => this.close(), options.timeout)
      }
      return this.ref.onClose.subscribe(ans => resolve(ans))
    })
  }

  show = (ctype: ComponentType<any>, config: PopupConfig): Promise<any> => {
    this.close()
    return new Promise(resolve => {
      const isTweet: boolean = (config.type == PopupType.Tweet)
      this.ref = this.dlg.open(ctype, {
        data: config,
        showHeader: false,
        header: config.title,
        closeOnEscape: true,
        dismissableMask: false,
        draggable: false,
        style: {
          "width": "50%",
          "max-width": "600px",
          "min-width": "250px"
        },
        baseZIndex: 10
      })
      if (isTweet && config?.timeout > 0) {
        this.handle = setTimeout(() => this.close(), config.timeout)
      }
      return this.ref.onClose.subscribe(ans => resolve(ans))
    })
  }

  /**
  
  [icon] [title]
  -------------------------------
    
  **/

  public tweet(text: string, spinner: boolean = false, timeout: number = 1500): Promise<any> {
    return this.show(PopupComponent,
      {
        type: PopupType.Tweet,
        text,
        spinner,
        icon: "pi-spinner",
        timeout
      })
  }

  public info(title: string, text: string): Promise<any> {
    return this.show(PopupComponent, {
      type: PopupType.Info,
      icon: "info-circle",
      title,
      text,
      acceptText: "DISMISS"
    })
  }

  public alert(title: string, text: string): Promise<any> {
    return this.show(PopupComponent, {
      type: PopupType.Info,
      icon: "exclamation-triangle",
      title,
      text,
      acceptText: "DISMISS"
    })
  }

  public confirm(title: string, text: string): Promise<any> {
    return this.show(PopupComponent, {
      type: PopupType.Confirm,
      icon: "question-circle",
      title,
      text,
      acceptText: "CONFIRM",
      rejectText: "CANCEL"
    })
  }

  public async prompt(title: string, text: string, prompt: string = null, placeholder: string = null, value: string = null) {
    return this.show(PopupComponent, {
      type: PopupType.Prompt,
      icon: "user-edit",
      title,
      text,
      prompt, value, placeholder,
      acceptText: "SUBMIT", rejectText: "CANCEL"
    })
  }

  public showJson(title: string, json: {}) {
    return this.show(PopupComponent, {
      type: PopupType.Json,
      title,
      json,
      icon: "code",
      acceptText: "CLOSE"
    })
  }

  public async formly(title: string, text: string, model: any, formConfig = null, formValid: (valid: boolean) => void = null) {
    const form = formConfig // || await this.createFields(Object.keys(model))
    return this.show(PopupComponent, {
      type: PopupType.Form,
      title,
      text,
      icon: "book",
      formConfig: form.controls,
      model,
      formValid,
      acceptText: "SUBMIT", rejectText: "CANCEL"
    })
  }

  public async summon(ctype: ComponentType<any>, model: any, formValid: (valid: boolean) => void = null) {
    return this.show(ctype, {
      type: PopupType.Component,
      model,
      formValid
    })
  }

  public async showError(e: any, msg = "An unknown error occurred") {
    if (e.name == "HttpErrorResponse")
      msg = e.error?.message || msg
    else if (e.name == "cms")
      msg = e.message || msg
    await this.alert("Error", msg)
  }
}
