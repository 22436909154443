import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { PopupService } from "app/services"
import { SharedModule } from "../shared"
import { PrimeModule } from "../prime.module"

@Component({
  selector: "app-footer",
  standalone: true,
  imports: [PrimeModule, SharedModule],
  template: `
    <div class="dialog-footer">
      <button *ngIf="rejectText?.length > 0" pButton
        class="p-button-text mr-2"
        icon="pi pi-{{rejectIcon}}"
        [label]="rejectText"
        (click)="onClose()"></button>
      <button *ngIf="acceptText?.length > 0" pButton #submitButton
        id="submitButton"
        type="submit"
        [attr.form]="formName"
        [disabled]="!valid"
        icon="pi pi-{{acceptIcon}}"
        [label]="acceptText"
        (click)="onAccept()"></button>
    </div>`
})
export class FooterComponent {
  @Input() formName = "form"
  @Input() valid: boolean = true

  @Input() rejectIcon = "times"
  @Input() rejectText
  @Output() reject = new EventEmitter()

  @Input() acceptIcon = "check"
  @Input() acceptText
  @Output() accept = new EventEmitter()

  constructor(public ns: PopupService) {
    // ctor
  }

  onClose() {
    this.ns?.close()
    this.reject.emit()
  }

  onAccept() {
    if (this.valid) {
      this.accept.emit()
    }
  }
}
